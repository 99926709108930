import { useStaticQuery, graphql } from 'gatsby'

export const careersData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 3494 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                acf {
                  title_introduction
                  desc_introduction
                  title1_description
                  desc1_description
                  title2_description
                  desc2_description
                  title_working
                  cases_working {
                    case1_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case3_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case2_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case4_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case5_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case6_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case7_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case8_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    case9_working {
                      img {
                        localFile {
                          childImageSharp {
                            fixed(width: 45, height: 45) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                  }
                  title1_advantage
                  desc1_advantage
                  title2_advantage
                  desc2_advantage
                  title_jobs
                  subtitle_jobs
                  jobs_jobs {
                    job1 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      responsibilities {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job2 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      responsibilities {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job3 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job4 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job5 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job6 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job7 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job8 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job9 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                    job10 {
                      department
                      position
                      desc
                      overview {
                        title
                        desc
                      }
                      about_you {
                        title
                        desc
                      }
                      offer {
                        title
                        desc
                      }
                    }
                  }
                }
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
