import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'

import Section from '../Section'
import { careersData } from '../../hooks/careers-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #ffffff;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;

  color: #333333;
`

const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
  @media (min-width: 512px) {
    width: 700px;
  }
`

const Advantage = props => {
  const {
    acf: {
      title1_advantage,
      desc1_advantage,
      title2_advantage,
      desc2_advantage,
    },
  } = careersData(props.pageContext.lang)
  return (
    <>
      <Section px={[15, 185]} background={<Bg />} pt={[90, 110]} pb={[80, 160]}>
        <Flex flexDirection="column" width={1}>
          <Box>
            <Title>{title1_advantage}</Title>
            <Desc
              dangerouslySetInnerHTML={{
                __html: desc1_advantage,
              }}
            />
          </Box>
          <Box pt={[50, 60]}>
            <Title>{title2_advantage}</Title>
            <Desc
              dangerouslySetInnerHTML={{
                __html: desc2_advantage,
              }}
            />
          </Box>
        </Flex>
      </Section>
    </>
  )
}

export default Advantage
