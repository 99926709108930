import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import Image from '../../static/img/careers/image_introduction.svg'
import Section from '../Section'
import Triangle from '../../static/img/careers/triangle_introduction.svg'
import TriangleMobile from '../../static/img/careers/triangleMobile_introduction.svg'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { careersData } from '../../hooks/careers-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
`

const Title = styled.h1`
  font-size: 42px;
  line-height: 52px;
  color: #ffffff;
  width: 230px;
  @media (min-width: 512px) {
    font-size: 48px;
    line-height: 124.84%;
    width: 470px;
  }
`

const Desc = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-size: 22px;
  line-height: 29px;

  color: #ac9bc8;
  @media (min-width: 512px) {
    width: 450px;
  }
`

const Introduction = props => {
  const {
    acf: { title_introduction, desc_introduction },
  } = careersData(props.pageContext.lang)
  const { introImage } = useStaticQuery(
    graphql`
      query {
        introImage: file(
          relativePath: { eq: "careers/imageMobile_introduction.png" }
        ) {
          childImageSharp {
            fixed(width: 170, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={[100, 130]} pb={[180, 130]}>
        <Title>{title_introduction}</Title>
        <Desc>{desc_introduction}</Desc>
      </Section>
      <DesktopFlex>
        <Triangle style={{ position: 'absolute', top: 400, left: 572 }} />
        <Image style={{ position: 'absolute', top: 120, right: 0 }} />
      </DesktopFlex>
      <MobileFlex>
        <Img
          style={{
            position: 'absolute',
            width: 315,
            height: 197,
            top: 436,
            right: 0,
            zIndex: 1,
          }}
          fixed={introImage.childImageSharp.fixed}
        />
        <TriangleMobile
          style={{ position: 'absolute', top: 580, right: 10, zIndex: 1 }}
        />
      </MobileFlex>
    </>
  )
}

export default Introduction
