import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Section from '../Section'
import { D, M, DesktopFlex } from '../breakpoints'
import { careersData } from '../../hooks/careers-data'
import Triangle from '../../static/img/careers/triangle_description.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #ffffff;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;

  color: #333333;
`

const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
  @media (min-width: 512px) {
    width: 700px;
  }
`

const Description = props => {
  const {
    acf: {
      title1_description,
      desc1_description,
      title2_description,
      desc2_description,
    },
  } = careersData(props.pageContext.lang)
  return (
    <>
      <Section
        px={[15, 185]}
        background={<Bg />}
        pt={[120, 130]}
        pb={[80, 160]}
      >
        <Flex flexDirection="column" width={1}>
          <Box>
            <Title>{title1_description}</Title>
            <Desc
              dangerouslySetInnerHTML={{
                __html: desc1_description,
              }}
            />
          </Box>
          <Box pt={[50, 60]}>
            <Title>{title2_description}</Title>
            <Desc
              dangerouslySetInnerHTML={{
                __html: desc2_description,
              }}
            />
          </Box>
        </Flex>
      </Section>
      <DesktopFlex>
        <Triangle
          style={{ position: 'absolute', top: 1727, left: 150 }}
        ></Triangle>
      </DesktopFlex>
    </>
  )
}

export default Description
