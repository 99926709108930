import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import Section from '../Section'
import { careersData } from '../../hooks/careers-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  z-index: -2;
  background: #362745;
`

const Title = styled.h2`
  font-size: 30px;
  line-height: 40px;

  color: #ffffff;
`

const Subtitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #e5e5e5;
`
const Department = styled.h4`
  font-size: 14px;
  line-height: 21px;
  text-transform: none;
  /* identical to box height */

  text-transform: uppercase;

  color: #ac9bc8;
`
const Position = styled.h3`
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #e6dbff;
`
const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #e5e5e5;
`
const TitleExtension = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-transform: uppercase;

  color: #cbaaff;
`

const initExpandedJobs = jobs => {
  let expandedJobs = []
  Object.values(jobs).map((e, i) => {
    if (e.department != '') {
      expandedJobs.push(false)
    }
  })
  return expandedJobs
}

const Jobs = props => {
  const {
    acf: { title_jobs, subtitle_jobs, jobs_jobs },
  } = careersData(props.pageContext.lang)
  const [expanded, setExpanded] = useState(initExpandedJobs(jobs_jobs))
  const [isHide, setIsHide] = useState(false)

  const toggleExpand = index => {
    setExpanded(expanded.map((job, i) => (i == index ? !job : job)))
  }

  useEffect(() => {
    toggleExpand(props.clickedJob)
    let jobClick = document.getElementById(`panel-${props.clickedJob}`)
    if (jobClick) {
      jobClick.scrollIntoView({ behavior: 'smooth' })
    }
  }, [props.clickedJob])

  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={[80, 130]} pb={[120, 130]}>
        <Flex flexDirection="column" width={[1, 0.5]} pb={[50, 70]}>
          <Title>{title_jobs}</Title>
          <Subtitle>{subtitle_jobs}</Subtitle>
        </Flex>
        {Object.values(jobs_jobs).map((e, i) => {
          if (e.department != '') {
            return (
              <ExpansionPanel
                id={`panel-${i}`}
                style={{
                  background: '#362745',
                  border: '1px solid #362745',
                  width: '940px',
                }}
                key={i}
                expanded={expanded[i] ? true : false}
                onChange={() => toggleExpand(i)}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#E6DBFF' }} />}
                >
                  <Flex flexDirection="column">
                    <Department>{e.department}</Department>
                    <Position>{e.position}</Position>
                  </Flex>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ background: '#2E203B' }}>
                  <Flex flexDirection="column" pt={[0, 20]}>
                    <Desc
                      dangerouslySetInnerHTML={{
                        __html: e.desc,
                      }}
                    />
                    <Box pt={[0, 20]}>
                      <TitleExtension>{e.overview.title}</TitleExtension>
                      <Desc
                        dangerouslySetInnerHTML={{
                          __html: e.overview.desc,
                        }}
                      />
                    </Box>
                    {e.responsibilities && (
                      <Box pt={[0, 20]}>
                        <TitleExtension>
                          {e.responsibilities.title}
                        </TitleExtension>
                        <Desc
                          dangerouslySetInnerHTML={{
                            __html: e.responsibilities.desc,
                          }}
                        />
                      </Box>
                    )}
                    <Box pt={[0, 20]}>
                      <TitleExtension>{e.about_you.title}</TitleExtension>
                      <Desc
                        dangerouslySetInnerHTML={{
                          __html: e.about_you.desc,
                        }}
                      />
                    </Box>
                    <Box pt={[0, 20]}>
                      <TitleExtension>{e.offer.title}</TitleExtension>
                      <Desc
                        dangerouslySetInnerHTML={{
                          __html: e.offer.desc,
                        }}
                      />
                    </Box>
                  </Flex>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          }
        })}
      </Section>
    </>
  )
}

export default Jobs
