import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex, M, D } from '../breakpoints'
import Triangle from '../../static/img/careers/triangle_officeImage.svg'
import TriangleMobile from '../../static/img/careers/triangleMobile_officeImage.svg'

const OfficeImage = props => {
  const { officeImage, officeImageMobile } = useStaticQuery(
    graphql`
      query {
        officeImage: file(relativePath: { eq: "careers/officeImage.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        officeImageMobile: file(
          relativePath: { eq: "careers/officeImageMobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <>
      <DesktopFlex>
        <Img
          style={{
            zIndex: -2,
            marginTop: '-4%',
            height: '100%',
            width: '100%',
          }}
          fluid={officeImage.childImageSharp.fluid}
        />
        <Triangle
          style={{
            position: 'absolute',
            top: 2859,
            right: 132,
            height: 200,
            width: 200,
          }}
        ></Triangle>
      </DesktopFlex>
      <MobileFlex>
        <Img
          style={{
            zIndex: -2,
            marginTop: '-4%',
            width: '100%',
            height: '100%',
          }}
          fluid={officeImageMobile.childImageSharp.fluid}
        />
        <TriangleMobile
          style={{ position: 'absolute', top: 3046, right: 26 }}
        ></TriangleMobile>
      </MobileFlex>
    </>
  )
}

export default OfficeImage
