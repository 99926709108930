import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import Section from '../Section'
import { careersData } from '../../hooks/careers-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  z-index: -2;
  background: #6F16FF;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;

  color: #ffffff;
`

const Department = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ac9bc8;
`
const Position = styled.h4`
  font-size: 20px;
  line-height: 29px;
  text-transform: none;
  /* identical to box height */

  color: #e6dbff;
`

const Grid = styled(Flex)`
  display: grid;
  gap: 20px 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 512px) {
    gap: 10px 100px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const JobsLinks = props => {
  const {
    acf: { title_jobs, subtitle_jobs, jobs_jobs },
  } = careersData(props.pageContext.lang)

  // const goTo = () => {
  //   window.scrollTo(0, 2000)
  //   return <Jobs hi={'holi'}></Jobs>
  // }

  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={[80, 70]} pb={[120, 130]}>
        <Title>{title_jobs}</Title>
        <Grid pt={10}>
          {Object.values(jobs_jobs).map((e, i) => {
            if (e.department != '') {
              return (
                <Flex key={i} flexDirection="column" px={[0, 1]} pb={[50, 10]}>
                  <Box
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.onClick(i)}
                  >
                    <Department>{e.department}</Department>
                    <Position>{e.position}</Position>
                  </Box>
                </Flex>
              )
            }
          })}
        </Grid>
      </Section>
    </>
  )
}

export default JobsLinks
