import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import Section from '../Section'
import Triangle from '../../static/img/careers/triangle_working.svg'
import { DesktopFlex } from '../breakpoints'
import { careersData } from '../../hooks/careers-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  z-index: -2;
  background: #362745;
`

const Title = styled.h2`
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
`

const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
`
const Grid = styled(Flex)`
  text-align: center;
  display: grid;
  gap: 20px 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 512px) {
    gap: 40px 40px;
    grid-template-columns: repeat(5, 1fr);
  }
`

const Working = props => {
  const {
    acf: { title_working, cases_working },
  } = careersData(props.pageContext.lang)
  return (
    <>
      <Section px={[15, 110]} background={<Bg />} pt={[80, 110]} pb={[50, 170]}>
        <Title>{title_working}</Title>
        <Grid width={10} pt={50}>
          {Object.values(cases_working).map((e, i) => {
            return (
              <Flex
                key={i}
                flexDirection="column"
                px={[0, 1]}
                width={150}
                pb={[50, 10]}
              >
                <Box>
                  <Img fixed={e.img.localFile.childImageSharp.fixed} />
                  <Desc>{e.desc}</Desc>
                </Box>
              </Flex>
            )
          })}
        </Grid>
        <DesktopFlex>
          <Triangle style={{ position: 'absolute', bottom: 120, right: 107 }} />
        </DesktopFlex>
      </Section>
    </>
  )
}

export default Working
