import React, { useState } from 'react'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'

import Advantage from '../components/careers/advantage'
import Description from '../components/careers/description'
import Footer from '../components/footer'
import Head from '../components/head'
import Introduction from '../components/careers/introduction'
import Jobs from '../components/careers/jobs'
import JobsLinks from '../components/careers/jobsLinks'
import Navbar from '../components/Navbar'
import OfficeImage from '../components/careers/officeImage'
import Working from '../components/careers/working'

import { careersData } from '../hooks/careers-data'
import '../styles/main.scss'

const Careers = props => {
  const [clickedJob, setClickedJob] = useState({})
  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = careersData(props.pageContext.lang)

  const handleClick = job => setClickedJob(job)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={props.pageContext.lang}
        title={yoast_wpseo_title}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
          }}
          {...props}
        />
        <Introduction {...props} />
        <JobsLinks {...props} onClick={handleClick} />
        <Description {...props} />
        <Working {...props} />
        <OfficeImage {...props} />
        <Advantage {...props} />
        <Jobs {...props} clickedJob={clickedJob} />
        <Footer {...props} style={{ marginTop: 80 }} />
      </Flex>
    </BreakpointProvider>
  )
}

export default Careers
